import consumer from "./consumer"

consumer.subscriptions.create("PingChannel", {
  connected() {},

  disconnected() {},

  received(data) {
    if (data.time)
      this.pong(data.time);

    if (data.rtt) {
      console.log(data);
    }
  },

  pong: function(time) {
    return this.perform('pong', { time: time });
  }
});
